#aunthenticatorComponent{
    padding-top: 5em;
    margin: 2vw 0 0 5vw;

    input{
        display: block;
        margin: 4px 0;
        width: 200px;
        border: 1px solid #C82736;
        border-radius: 6px;
    }

    button{
        margin: 12px 0;
        color: #C82736;
        background-color: #FFFFFF;
        padding: 7px 20px;
        border: 1px solid #C82736;
        border-radius: 6px;
        cursor: pointer;

        &:hover{
            color: #FFFFFF;
            background-color: #C82736;
        }
    }
}