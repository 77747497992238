#debtPagePreviewComponent{
    padding: 15px 40px;
    height: 90%;
    overflow-y: scroll;

    #debtSummary{
        display: flex;
        justify-content: space-between;
        color: #FFFFFF;
        margin: 10px 0;
        cursor: pointer;
    }

    .hideDebts{
        display: none;
    }

    #updatedDebtRecords{
        box-shadow: 1px 1px 4px rgb(0 0 0 / 0.2);
        background-color: #FFFFFF;
        margin: 0;
        padding: 15px 0 8px 10px;
        border-bottom: 2px solid #E9E9ED;
    }

    h4{
        font-size: 18px;
    }

    #debtRecords{
        margin: 25px 0 15px 0;
    }

    button{
        color: #C82736;
        background-color: #FFFFFF;
        padding: 5px 8px;
        border: 1px solid #C82736;
        border-radius: 6px;
        cursor: pointer;
        display: block;
        float: right;

        &:hover{
            color: #FFFFFF;
            background-color: #C82736;
        }
    }
}