#stockPagePreviewComponent{
    padding: 15px 40px;
    height: 90%;
    overflow-y: scroll;

    thead{           
        color: white;
    }

    h4{
        font-size: 18px;
    }

    #debtRecords{
        margin-bottom: 50px;
    }
}