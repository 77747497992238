#footerPreview{
    box-shadow:  0px 1px 4px #888, 0px -1px 1px rgb(0 0 0 / 0.2);;
    background-color: beige;
    min-height: 600px;
    position: absolute;
    bottom: -50;
    padding: 0 100px;

    #footerRow{
        padding: 20px 0;
        margin: 0;
    }

    #physicalAddress{
        width: 48vw;
        margin-right: 40px;
        iframe{
            width: 100%;
            height: 500px;
            border-radius: 10px;
            // height: 500px;
            border: none;
        }
    }

    #feedbackFormColumn{
        width: 46vw;

        #feedbackForm{
            font-size: 18px;
            font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    
            p{
                margin: 0 0 3px 0;
            }
    
            input, textarea, button{
                display: block;
                margin: 8px 0;
            }
    
            button{
                color: #C82736;
                background-color: beige;
                padding: 5px 8px;
                border: 1px solid #C82736;
                border-radius: 6px;
                cursor: pointer;
    
                &:hover{
                    color: #FFFFFF;
                    background-color: #C82736;
                }
            }
    
            input{
                font-size: 15px;
                padding: 5px 5px;
                font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            }
    
            textarea{
                height: 200px;
                width: 80%;
                overflow-y: scroll;
                resize: none;
                display: block;
                padding: 5px 5px;
                font-size: 15px;
                font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            }
        }
    }
}

@media(max-width: 1000px){
    #footerPreview{
        #physicalAddress{
            width: 48vw;
            margin-right: 40px;
            iframe{
                width: 100%;
                height: 500px;
                border-radius: 10px;
                // height: 500px;
                border: none;
            }
        }
    
        #feedbackFormColumn{

            #feedbackForm{

                textarea{
                    height: 200px;
                    width: 500px;
                }
            }


        }
    }
}

@media(max-width: 790px){
    #footerPreview{
        height: inherit;
        padding: 0 2px;

        #physicalAddress{
            margin-top: 10px;
            width: 100vw;
            margin-right: 0;
            iframe{
                width: 90vw;
                height: 250px;
            }
        }

        p{
            font-size: 13px;
        }

        #feedbackFormColumn{
            width: 100vw;

            #feedbackForm{
                font-size: 13px;
    
                input{
                    font-size: 13px;
                    padding: 2px 2px;
                    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
                }
        
                textarea{
                    height: 150px;
                    width: 90%;
                    padding: 2px 2px;
                    font-size: 13px;
                    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
                }
            }
        }
    }
}
