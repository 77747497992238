#salesPagePreviewComponent{
    padding: 15px 40px;
    height: 90%;
    overflow-y: scroll;


    h4{
        font-size: 18px;
    }

    .bg-dark{
        color: #FFFFFF;
    }

    // #salesRepsTables{
    //     display: flex;
    // }
    
    #salesRepSelect{
        background-color: #343A40;
        border: none;
        padding: 1px 4px;
        color: #FFFFFF;
        font-size: 17px;
        font-weight: 700;
        cursor: pointer;
    }

    #salesRecords{
        margin-bottom: 50px;
        
        .dashboardEntry{
            box-shadow: 1px 1px 4px rgb(0 0 0 / 0.2);
            background-color: #FFFFFF;
            padding: 10px 15px 10px 15px;
            width: 30%;
            margin: 0 22px;

            input{
                display: block;
                margin: 3px 0 11px 0;
            }
        
        }
    }

    button{
        color: #C82736;
        background-color: #FFFFFF;
        padding: 5px 8px;
        border: 1px solid #C82736;
        border-radius: 6px;
        cursor: pointer;
        display: block;
        float: right;

        &:hover{
            color: #FFFFFF;
            background-color: #C82736;
        }
    }
}