#navigationPreviewComponent{
    box-shadow: 0 1px 4px rgb(0 0 0 / 0.2);
    height: 85px;

    #mobileLogo{
        display: none;
    }

    .hamburgerMenu img{
        display: none;
    }
    
    .navItemsContainer{
        display: flex;
        margin: 0 auto;

        .navItem{
            color: #333333;
            cursor: pointer;
            margin-top: 10px;
        }
    }

    #navContainer{
        display: flex;
        margin: 0 auto;
    }

    #lushLogo{
        width: 100px;
    }

    #navToggle{
        position: relative;
        right: 0;
    }
}

@media(max-width: 1100px){
    #navigationPreviewComponent{

        #lushLogo{
            width: 90px;
        }

        #mobileLogo{
            display: block;
        }

        #laptopLogo{
            display: none;
        }

        #navItemsMobile{
            display: none;
        }

        #navModal{
            top: 0;
            position: absolute;
            height: 100vh;
            width: 55vw;
            left: 0;
            background-color: rgba(0,0,0,0.6);
            z-index: 6;
        }

        .navItemsContainer{
            position: absolute;
            display: block;
            top: 85px;
            right: 0;
            height: 100vh;
            width: 50vw;
            background-color: #FFFFFF;
            text-align: right;

            .navItem{
                border-bottom: 1px solid #F5F5F5;
                padding-right: 10px;
            }
        }

        .hamburgerMenu img{
            display: block;
            height: 2em;
            margin-right: 35px;
            position: relative;
            right: -28px;
            top: 0;
        }
        
        // #navItemsMobile{
        //     display: none;
        // }
    }
}