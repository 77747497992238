#cashPagePreviewComponent{
    padding: 15px 40px;
    height: 90%;
    overflow-y: scroll;

    h4{
        font-size: 18px;
    }

    #cashRecords{
        margin-bottom: 50px;
    }
}