#applicationFormPreviewComponent{
    position: relative;

    #formIntro{
        width: 100%;
        text-align: center;
    }

    form{
        // width: 50%;
        padding: 0 20% 30px 20%;
        margin: 20px auto 20px auto;
        
        fieldset{
            background-color: #FFFFFF;
            padding: 25px 40px;
            box-shadow: 0 1px 8px rgb(0 0 0 / 0.2);
            // margin: 10px 0;
            background-color: #FFFFFF;
            border: none;
            border-radius: 20px;
        }

        label{
            display: block;
        }

        input, select, textarea{
            display: block;
            margin: 3px 0 12px 0;
            width: 200px;
        }
        
        textarea{
            width: 500px;
            overflow-y: scroll;
        }

        input{
            height: 30px;
            width: 300px;
            border-radius: 15px;
            border: 1px solid grey;
            padding: 2px 20px;
        }

        input[type=file]{
            border: none;
            padding: 0;
            border-radius: 0;
            margin-top: 5px;
        }

        #title{
            width: 550px;
        }

        .rsw-editor{
            width: 96%;
            margin: 3px 0 12px 0;
            min-height: 200px;
        }

        button{
            color: #C82736;
            background-color: #FFFFFF;
            padding: 7px 10px;
            border: 1px solid #C82736;
            border-radius: 6px;
            cursor: pointer;

            &:hover{
                color: #FFFFFF;
                background-color: #C82736;
            }
        }
    }
}

@media(max-width: 1100px){
    #applicationFormPreviewComponent{

        #formIntro{
            h2{
                font-size: 20px;
            }
        }

        form{
            padding: 0 2% 30px 2%;

            input, select, textarea{
                margin: 3px 0 12px 0;
                width: 95%;
            }

            textarea{
                width: 80%;
            }
        }
    }
}