#careersPreviewComponent{

    #careersComponent{
        padding: 5px 25% 30px 25%;
        // position: relative;

        h1{
            font-size: 28px;
            font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        }

        h4{
            font-size: 18px;
            font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            text-align: center;
            color: red;
        }

        .careerList{
            padding: 38px 40px;
            box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
            margin: 15px 0;
            position: relative;
            background-color: #FFFFFF;
            cursor: pointer;
        }

        #baker{
            img{
                content: url("../../../assets/images/baker.jpeg");
                height: 80%;
                position: absolute;
                right: 30px;
                bottom: 10%;
            }
        }

        #manager{
            img{
                content: url("../../../assets/images/manager.jpg");
                height: 80%;
                position: absolute;
                right: 65px;
                bottom: 10%;
            }
        }

        #distributor{
            img{
                content: url("../../../assets/images/delivery.jpg");
                height: 80%;
                position: absolute;
                right: 60px;
                bottom: 10%;
            }
        }

        #assistant{
            img{
                content: url("../../../assets/images/assistant.png");
                height: 80%;
                position: absolute;
                right: 70px;
                bottom: 10%;
            }
        }
    }
}


@media(max-width: 1100px){
    #careersPreviewComponent{

        #careersComponent{
            padding: 5px 2% 30px 2%;

            h1{
                font-size: 16px;
            }

                
            #baker{
                img{
                    height: 55%;
                    bottom: 20%;
                }
            }

            #manager{
                img{
                    height: 60%;
                    right: 48px;
                    bottom: 20%;
                }
            }

            #distributor{
                img{
                    height: 55%;
                    right: 45px;
                    bottom: 20%;
                }
            }

            #assistant{
                img{
                    height: 70%;
                    right: 40px;
                    bottom: 15%;
                }
            }
        }

    }
}