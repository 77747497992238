#targetsPreviewComponent{
    padding: 15px 40px;

    h4{
        font-size: 18px;
    }

    #weeklyTargets{
        margin-bottom: 40px;
    }

}