#operationsMenuPreviewComponent{
    margin-bottom: 30px;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 0.2);
    background-color: #FFFFFF;
    height: 40px;

    #operationsMenuItems{
        height: 100%;
        display: flex;
        justify-content: center;
        
        p{
            margin: 5px 30px 0 0;
            font-weight: 600;
            cursor: pointer;
        }
    }
}