.dashboardDisplay{
    box-shadow: 1px 1px 4px rgb(0 0 0 / 0.2);
    background-color: #FFFFFF;
    padding: 0 15px 2px 15px;
    width: 30%;
    margin: 0 12px;

    .displayHeader{
        font-size: 18px;
    }

    #skews{

        tr{
            position: relative;

            #indicatorBad{
                border-left: 4px solid red;
            }
        
            #indicatorGood{
                border-left: 4px solid green;
            }
        }

        .bg-dark{
            color: #FFFFFF;
        }

        .category{
            text-transform: capitalize;
        }
    }

    .noIndicator{
        height: 3px;
        background-color: none;
    }
}