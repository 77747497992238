#productsPagePreviewComponent{
    padding: 15px 40px;
    height: 90%;
    overflow-y: scroll;

    #oldPrices{
        box-shadow: 1px 1px 4px rgb(0 0 0 / 0.2);
        background-color: #FFFFFF;
        margin: 0 0 40px 0;
        padding: 15px 0 8px 10px;
    }

    h4{
        font-size: 18px;
    }

    button{
        color: #C82736;
        background-color: #FFFFFF;
        padding: 5px 8px;
        border: 1px solid #C82736;
        border-radius: 6px;
        cursor: pointer;
        display: block;
        float: right;

        &:hover{
            color: #FFFFFF;
            background-color: #C82736;
        }
    }
}